<script>
import MenuTarefas from "components/suporte/components/task/include/MenuTarefas"
import {TASK_FIXED_QUEUE} from "@/domain/project/helpers/types"
import {listQueue} from "@/domain/tarefas/services/queue"

export default {
  name: 'SuporteTarefas',
  provide: function () {
    return {
      page: this,
      container: this
    }
  },
  mixins: [],
  components: {
    MenuTarefas
  },
  data() {
    return {
      loading: true,
      routeName: 'suporte.tarefas',
      queues: [],
      fixedQueues: [],
      /*menuActive: {
        name: 'opened',
        label: 'Em aberto',
      },*/
      menuActive: {},
    }
  },
  mounted() {
    this.loadQueues()
  },
  computed: {},
  methods: {
    loadQueues () {
      this.loading = true
      listQueue(200, 1, '&active=1&requestFixedQueues=1&onlyNoProject=1&withStats=1')
          .then(response => {
            this.queues = response.data._queues
            this.fixedQueues = response.data._fixedQueues
            if (this.$route.params.fila) {
              if (isNaN(Number(this.$route.params.fila))) {
                if (typeof this.fixedQueues[this.$route.params.fila] !== 'undefined') {
                  this.menuActive = {
                    name: this.$route.params.fila,
                    label: TASK_FIXED_QUEUE[this.$route.params.fila].title
                  }
                }
              } else if (this.queues) {
                const fila = this.queues.find(q => Number(q.id) === Number(this.$route.params.fila))
                if (fila) {
                  this.menuActive = {
                    name: 'id:' + fila.id,
                    label: fila.name
                  }
                }
              }
            }
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    loadQueue(queue) {
      console.log('Load queue: ', queue)
      this.$router.push({
        name: 'suporte.tarefas.fila',
        params: {fila: queue.replace('id:', '')}
      })
    }
  },
  meta: {
    title: 'Tarefas',
    name: 'Tarefas'
  }
}
</script>

<template>
  <div class="scontent">
    <div style="height: 0; max-height: 0">
      <menu-tarefas v-show="!loading" ref="menuTarefas" />
<!--      <project-tab :projeto="projeto"/>-->
    </div>
    <router-view />
  </div>
</template>
